import Repository from "./Repository";
const ADD_USERS = "organization/invite_users";
const GET_USERS = "organization/invitations";
const GET_USERS_JOINED = "organization/invitations/joined";
const REVOKE_INVITATION = "organization/revoke_invitation";
const UNDO_REVOKED_USER = "/organization/active_revoked_invitation";
const REFRESH_INVITATION = "organization/refresh_invitation";
const DELETE_EMAIL = "organization/invitations";
const GET_ME = "organization/get-me";
const GET_INVOICES = "organization/invoices";
const UDPATE_PROFILE = "organization/update-profile";
const UDPATE_PASSWORD = "organization/update-password";
const CURRENT_SUBSCRIPTION = "organization/get-subscription";
const CancelationActiveBundle = "es/onboard/organization/checkout";
const UPGRADE_DOWNGRADE_SUBSCRIPTION = "es/onboard/organization/subscription";
const USERSTATS = "/organization/subscription-stats";
const GETCARD = "/organization/cards";
const UPDATECARD = "/organization/set_default_card?payment_method_id=";
const DELETECARD = "/organization/delete-card";
const ADDCARD = "/organization/add_card";
const GET_TRAINING_STATS = "/organization-stats/interview-sessions-stats";
const GET_SIMULATION_STATS = "/organization-stats/interview-simulation-stats";
const GET_RESUME_STATS = "/organization-stats/resume-stats";
const GET_USER_DETAILS_STATS = "/organization-stats/interview-training-sessions";
const GET_USER_RESUME_STATS = "/organization-stats/customer-resume-stats";
const TRIAL_PLAIN = "es/onboard/organization/subscribe_trial";
const REACTIVATE_PACKAGE = "es/onboard/organization/retry_payment";
const COMPLTED_RESUME_STATS = "/analytics/get-completed-count";
const USER_AVG_SCORE = "/analytics/average-interview-score";
const FEEDBACK_SATISFACTION = "/analytics/feedback-and-satisfaction";
const USER_TRAINING_STATUS = "/analytics/get-completed-interview-sessions";
const USER_SIMULTAIONS_STATUS = "/analytics/get-completed-simulation-count";
const INTERVIEW_SIMULATION_SESSIONS = "/organization-stats/interview-simulation-sessions";
const GET_USER_INTERVIEW = "/resume/my";
const GET_SINGLE_RESUME = "resume/";
const GET_SKILL_GAP = "onboarding/fetch_skill_gap_analysis";
const GET_USER_ONBOARDING = "onboarding/read";
const GET_USER_LINKEDIN_PROFILE = "customer/linkedin_profile";
const GET_USER_EMPLOYMENT_STATUS = "customer/employment_status";
const GET_USER_P_ROUNDS = "/analytics/interview_training_sessions_list";
const P_ROUNDS_FEEDBACK = "/dashboard/interview-training/feedback";
const SIMULATION_FEEDBACK = "/dashboard/interview-simulation/feedback";
const GET_USER_SIMULATION = "/analytics/interview_simulation_sessions_list";
const GET_DECLINE_ERROR = "es/onboard/organization/subscription_status";
const GET_CONTEXT_VIEW = "/organization/generate_login_link";
const GET_REMOVE_SUBSCRIPTION_QUANTITY = "es/onboard/organization/removable_subscription_quantity";
const DELETE_REMOVE_SUBSCRIPTION = "es/onboard/organization/remove_subscription_quantity";
const MONTHLY_AVG_SCORE = "customer/get_monthly_average_score";
const CAREER_READINESS = "customer/career_readiness";
const SUMMARY_REPORT = "customer/user_summary";
const LOGIN_FREQUENCY = "customer/login_frequency";
const TIME_SPENT = "customer/time_spent_on_platform";
const SIMULATION_SCORE_PER_USER = "customer/average_simulation_score";
const P_ROUND_SCORE_PER_USER = "customer/average_training_score";
const SIMULATION_SESSION_TOP_SCORE = "customer/top_simulation_score";
const P_ROUND_SESSION_TOP_SCORE = "customer/top_training_score";
const INCOMPLETION_RATES = "customer/incompletion_rates";
const REVOKE_CANCEL_SUBSCRIPTION = "/es/onboard/organization/revoke_subscription_cancellation";
const TOTAL_USER_COUNT = "organization-stats/total_user_count";
const TODAY_ACTIVE_CUSTOMER = "organization-stats/active_customers_today";
const AVG_INTERVIEW_SIMULATION = "organization-stats/average_simulation_score";
const AVG_P_ROUND_SCORE = "organization-stats/average_training_score";
const ORG_INCOMPLETION_RATES = "organization-stats/incompletion_rates";
const RESUME_COMPLETION_RATE = "organization-stats/resume_completion_rate";
const GET_USER_INFO_BY_ID = "customer/info";

const dashboardRepository = {
  addUsers(payload) {
    return Repository.post(`${ADD_USERS}`, payload);
  },
  getAllUsers(payload) {
    return Repository.get(`${GET_USERS}?${payload}`);
  },
  getAllJoinedUsers() {
    return Repository.get(`${GET_USERS_JOINED}`);
  },
  revokeInvitaion(payload) {
    let updatedPayload = {
      email: payload.email,
    };
    return Repository.put(
      `${payload?.status == "revoked" ? UNDO_REVOKED_USER : REVOKE_INVITATION}`,
      updatedPayload
    );
  },
  refreshInvitation(payload) {
    // console.log(payload, "payload");
    return Repository.post(`${REFRESH_INVITATION}`, payload);
  },
  deleteInvitaion(payload) {
    const email = {
      email: payload,
    };
    return Repository.post(`${DELETE_EMAIL}`, email);
  },
  getProfile() {
    return Repository.get(`${GET_ME}`);
  },
  udpateProfile(payload) {
    return Repository.put(`${UDPATE_PROFILE}`, payload);
  },
  updatePassword(payload) {
    // console.log(payload, "payload");
    return Repository.put(`${UDPATE_PASSWORD}`, payload);
  },
  getSubscription() {
    return Repository.get(`${CURRENT_SUBSCRIPTION}`);
  },

  cancelSub() {
    return Repository.post(`/es/onboard/organization/cancel_subscription?cancel_immediately=False`);
  },
  getInvoices() {
    return Repository.get(`${GET_INVOICES}`);
  },
  CancelationActiveBundle(payload) {
    // console.log(payload, "payload");
    return Repository.post(
      `${CancelationActiveBundle}?quantity=${payload?.quantity}&bundlePlanId=${payload?.pricingId}`
    );
  },
  checkOutSessionBundle(payload) {
    // console.log(payload, "payload");
    return Repository.post(
      `${UPGRADE_DOWNGRADE_SUBSCRIPTION}/modify?new_plan_id=${payload?.bundlePlanId}`
    );
  },
  GET_Stats() {
    return Repository.get(`${USERSTATS}`);
  },
  getPaymentCard() {
    return Repository.get(`${GETCARD}`);
  },
  updatePaymentCard(payload) {
    return Repository.put(`${UPDATECARD}${payload}`);
  },
  deleteCard(payload) {
    return Repository.delete(`${DELETECARD}/${payload}`);
  },
  addPaymentCard(payload) {
    return Repository.post(`${ADDCARD}`, payload);
  },
  allTrainingStats() {
    return Repository.get(`${GET_TRAINING_STATS}`);
  },
  allSimulationStats() {
    return Repository.get(`${GET_SIMULATION_STATS}`);
  },
  orgResumeStats() {
    return Repository.get(`${GET_RESUME_STATS}`);
  },
  getUserDetailStats(payload) {
    return Repository.get(`${GET_USER_DETAILS_STATS}?customer_id=${payload}`);
  },
  getUserResumeStats(payload) {
    return Repository.post(`${GET_USER_RESUME_STATS}`, payload);
  },
  activeFreeTrial() {
    return Repository.post(`${TRIAL_PLAIN}`);
  },
  reActivatePackage(payload) {
    return Repository.post(`${REACTIVATE_PACKAGE}?invoice_id=${payload}`);
  },
  getCompletedResumeStats(payload) {
    return Repository.get(`${COMPLTED_RESUME_STATS}?customer_id=${payload}`);
  },
  userAvgScore(payload) {
    return Repository.get(`${USER_AVG_SCORE}?customer_id=${payload}`);
  },
  userFeedbackSatisfaction(payload) {
    return Repository.get(`${FEEDBACK_SATISFACTION}?customer_id=${payload}`);
  },
  trainingStatus(payload) {
    return Repository.get(`${USER_TRAINING_STATUS}?customer_id=${payload}`);
  },
  simulationStatus(payload) {
    return Repository.get(`${USER_SIMULTAIONS_STATUS}?customer_id=${payload}`);
  },
  interviewsimulationStatus(payload) {
    return Repository.get(`${INTERVIEW_SIMULATION_SESSIONS}?customer_id=${payload}`);
  },
  getUserResume(payload) {
    return Repository.get(`${GET_USER_INTERVIEW}?customer_id=${payload}`);
  },
  getSingleResume(payload) {
    return Repository.get(`${GET_SINGLE_RESUME}?resume_id=${payload}`);
  },
  getSkillGapAnalysis(payload) {
    return Repository.get(`${GET_SKILL_GAP}?customer_id=${payload}`);
  },
  getUserOnBoarding(payload) {
    return Repository.get(`${GET_USER_ONBOARDING}?customer_id=${payload}`);
  },
  getUserLinkedinProfile(payload) {
    return Repository.get(`${GET_USER_LINKEDIN_PROFILE}?customer_id=${payload}`);
  },
  setUserLinkedinProfile(id, payload) {
    return Repository.put(`${GET_USER_LINKEDIN_PROFILE}?customer_id=${id}`, payload);
  },
  getUserEmploymentStatus(payload) {
    return Repository.get(`${GET_USER_EMPLOYMENT_STATUS}?customer_id=${payload}`);
  },
  setUserEmploymentStatus(id, payload) {
    return Repository.put(`${GET_USER_EMPLOYMENT_STATUS}?customer_id=${id}`, payload);
  },
  UserPRounds(payload) {
    return Repository.get(
      `${GET_USER_P_ROUNDS}?limit=10&page=${payload.page}&customer_id=${payload.customer_id}`
    );
  },
  pRoundFeedback(payload) {
    return Repository.get(
      `${P_ROUNDS_FEEDBACK}?interviewTrainingSessionId=${payload.id}&customer_id=${payload.customer_id}`
    );
  },
  UserSimulations(payload) {
    return Repository.get(
      `${GET_USER_SIMULATION}?limit=10&page=${payload.page}&customer_id=${payload.customer_id}`
    );
  },
  simulationFeedback(payload) {
    return Repository.get(
      `${SIMULATION_FEEDBACK}?interviewSimulationSessionId=${payload.id}&customer_id=${payload.customer_id}`
    );
  },
  getDeclinedError() {
    return Repository.get(`${GET_DECLINE_ERROR}`);
  },
  getContextView(id) {
    return Repository.get(`${GET_CONTEXT_VIEW}?customer_id=${id}`);
  },
  getRemoveUsersLimit() {
    return Repository.get(GET_REMOVE_SUBSCRIPTION_QUANTITY);
  },
  deleteSeats(payload) {
    return Repository.post(`${DELETE_REMOVE_SUBSCRIPTION}?no_of_seats=${payload}`);
  },
  monthlyAvgScore(payload) {
    return Repository.get(`${MONTHLY_AVG_SCORE}?customer_id=${payload}`);
  },
  careerReadiness(payload) {
    return Repository.get(`${CAREER_READINESS}?customer_id=${payload}`);
  },
  summaryReport(payload) {
    return Repository.get(`${SUMMARY_REPORT}?customer_id=${payload}`);
  },
  loginFrequency(payload) {
    return Repository.get(`${LOGIN_FREQUENCY}?customer_id=${payload}`);
  },
  timeSpent(payload) {
    return Repository.get(`${TIME_SPENT}?customer_id=${payload}`);
  },
  simulationScorePerUser(payload) {
    return Repository.get(`${SIMULATION_SCORE_PER_USER}?customer_id=${payload}`);
  },
  pRoundScorePerUser(payload) {
    return Repository.get(`${P_ROUND_SCORE_PER_USER}?customer_id=${payload}`);
  },
  simulationSessionTopScore(payload) {
    return Repository.get(`${SIMULATION_SESSION_TOP_SCORE}?customer_id=${payload}`);
  },
  pRoundSessionTopScore(payload) {
    return Repository.get(`${P_ROUND_SESSION_TOP_SCORE}?customer_id=${payload}`);
  },
  incompletionRates(payload) {
    return Repository.get(`${INCOMPLETION_RATES}?customer_id=${payload}`);
  },
  handleRevokeSubscriptionCancel() {
    return Repository.post(`${REVOKE_CANCEL_SUBSCRIPTION}`);
  },
  totalUserCount() {
    return Repository.get(`${TOTAL_USER_COUNT}`);
  },
  todayActiveCustomer() {
    return Repository.get(`${TODAY_ACTIVE_CUSTOMER}`);
  },
  avgInterviewSim() {
    return Repository.get(`${AVG_INTERVIEW_SIMULATION}`);
  },
  avgPRoundScore() {
    return Repository.get(`${AVG_P_ROUND_SCORE}`);
  },
  orgIncompletionRates() {
    return Repository.get(`${ORG_INCOMPLETION_RATES}`);
  },
  resumeCompletionRate() {
    return Repository.get(`${RESUME_COMPLETION_RATE}`);
  },
  getUserInfoById(id) {
    return Repository.get(`${GET_USER_INFO_BY_ID}/${id}`);
  },
  updateUserInfoById({ id, payload }) {
    return Repository.put(`${GET_USER_INFO_BY_ID}/${id}`, payload);
  },
  getResumeFeedback(id) {
    return Repository.get(`/resume/generate_resume_feedback?resume_id=${id}`);
  },
};

export default dashboardRepository;

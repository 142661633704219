import React, { useEffect } from "react";
import { Box, Avatar, Typography } from "@mui/material";
import moment from "moment";
import UserImg from "assets/images/logos/user.png";
import { useSelector } from "react-redux";

const FeedbackMessageComponent = ({ feedbackIndex, feedback }) => {
  const { orgTheme } = useSelector((state) => state.oraganizationTheme);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (feedback) {
      const element = document.getElementById(feedback.created_at);
      if (element) {
        element.parentElement.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [feedback]);

  return (
    <Box
      key={feedbackIndex}
      id={feedback?.created_at}
      sx={{
        backgroundColor: "#f7f7f7",
        py: 2,
        borderRadius: "12px",
        my: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "7%",
          }}
        >
          <Avatar
            src={orgTheme?.file_url || UserImg}
            alt="Profile Image"
            sx={{ width: 50, height: 50 }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "93%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" component="span" sx={{ color: "#000", fontWeight: 500 }}>
              {user?.fullName}
            </Typography>
            <Typography variant="h6" component="span" sx={{ color: "#000" }}>
              &nbsp;
              <Typography
                variant="h6"
                component="span"
                sx={{
                  color: "#000",
                  fontWeight: 400,
                  fontSize: "1rem",
                  textTransform: "capitalize",
                }}
              >
                =&gt;
              </Typography>
              &nbsp;
              {feedback?.customer_name}
            </Typography>
            <Typography variant="h6" component="span" sx={{ color: "#000", fontWeight: 400 }}>
              &nbsp;-&nbsp;
              {moment(feedback?.created_at).format("hh:mm A")}
            </Typography>
          </Box>
          <Typography
            variant="body1"
            sx={{
              color: "#000",
              mt: { xs: 0 },
              fontWeight: 400,
              lineHeight: 1.5,
            }}
          >
            {feedback?.feedback}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default FeedbackMessageComponent;

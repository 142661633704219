import { Card, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React from "react";

const ResumeFeedbackModal = ({ modal, handleClose, storeResumeFeedback }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    maxHeight: "80vh", // Limits the height to 80% of the viewport height
    boxShadow: 24,
    p: 4,
    overflowY: "auto", // Enables scrolling when content overflows
  };
  return (
    <Modal
      open={modal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDBox>
          <MDTypography variant="h6">Resume Feedback</MDTypography>
        </MDBox>
        <MDBox mx={1} p={1} mt={1}>
          <MDBox>
            <MDTypography variant="h5" m={1}>
              Feedback:
            </MDTypography>
            <hr />
            <MDTypography variant="h6" my={2} mx={1}>
              {storeResumeFeedback?.feedback}
            </MDTypography>
          </MDBox>

          <MDBox
            mt={3}
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "start",
              alignItems: "center",
              background: "#9eeaf9",
              padding: "10px",
              borderRadius: "12px",
            }}
          >
            <h5 className="my-0">Nexa Resume Score:</h5>
            <span className="score">{storeResumeFeedback?.score}</span>
          </MDBox>
          <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
            <MDButton variant="gradient" color="info" onClick={handleClose}>
              Close
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    </Modal>
  );
};

export default ResumeFeedbackModal;

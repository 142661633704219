import { Button, Grid } from "@mui/material";
import Loader from "components/Loader";
import MDBox from "components/MDBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { careerReadiness } from "store/Slices/usersSlice";
import { loginFrequency } from "store/Slices/usersSlice";
import { simulationScorePerUser } from "store/Slices/usersSlice";
import { simulationSessionTopScore } from "store/Slices/usersSlice";
import { incompletionRates } from "store/Slices/usersSlice";
import { pRoundSessionTopScore } from "store/Slices/usersSlice";
import { pRoundScorePerUser } from "store/Slices/usersSlice";
import { timeSpent } from "store/Slices/usersSlice";
import { summaryReport } from "store/Slices/usersSlice";
import { monthlyAvgScore } from "store/Slices/usersSlice";
import { getUserFeedbackSatisfaction } from "store/Slices/usersSlice";
import { getUserAvgScore } from "store/Slices/usersSlice";
import { getUserSimultionStatus } from "store/Slices/usersSlice";
import { userResumeStats } from "store/Slices/usersSlice";
import { getUserCompletedResumes } from "store/Slices/usersSlice";
import { getUserTrainingStatus } from "store/Slices/usersSlice";
import { getUserSimulationStats } from "store/Slices/usersSlice";
import { getUserTrainingStats } from "store/Slices/usersSlice";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import jsPDF from "jspdf";
import "jspdf-autotable";

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const UserStats = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const spiltID = id?.split("_")?.[0];
  const {
    userTrainingStats,
    userSimulationStats,
    userResumes,
    resumeAvgScore,
    compltedResumeCount,
    averageScore,
    satisfaction,
    trainingStatus,
    simulationStatus,
    loading,
    roundsCompletionData,
    simulationData,

    monthlyAvgScoreVal,
    careerReadinessVal,
    summaryReportVal,
    loginFrequencyVal,
    timeSpentVal,
    simulationScorePerUserVal,
    pRoundScorePerUserVal,
    simulationSessionTopScoreVal,
    pRoundSessionTopScoreVal,
    incompletionRatesVal,
  } = useSelector((state) => state.users);

  const formatTimeSpent = (minutes) => {
    const duration = moment.duration(minutes, "minutes");
    const hours = Math.floor(duration.asHours());
    const mins = duration.minutes();
    return `${hours}hr : ${mins} min`;
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    // Customer Info
    doc.setFontSize(18);
    doc.text("Customer Information", 14, 22);

    const customerInfo = summaryReportVal.customer_info;
    doc.setFontSize(12);
    doc.text(`Name: ${customerInfo.name}`, 14, 32);
    doc.text(`Contact: ${customerInfo.contact}`, 14, 38);
    doc.text(`Employment Status: ${customerInfo.employment_status}`, 14, 44);
    doc.text(`Education: ${customerInfo.education}`, 14, 50);
    doc.text(`Address: ${customerInfo.address}`, 14, 56);
    doc.text(`LinkedIn Profile: ${customerInfo.linkedIn_profile}`, 14, 62);

    // Onboarding Info
    doc.setFontSize(18);
    doc.text("Onboarding Information", 14, 72);

    const onboardingData = summaryReportVal.onboarding_info.map((info) => [
      info.question,
      info.answer,
    ]);
    doc.autoTable({
      startY: 78,
      head: [["Question", "Answer"]],
      body: onboardingData,
      theme: "striped",
      headStyles: { fillColor: [22, 160, 133] },
      columnStyles: {
        0: { cellWidth: 60 }, // Adjust the width as needed
        1: { cellWidth: "auto" }, // Allow the second column to auto-adjust width
      },
      margin: { top: 10 },
      styles: {
        cellPadding: 3,
        fontSize: 10,
        overflow: "linebreak",
      },
    });

    // Training Sessions
    const finalY = doc.lastAutoTable.finalY + 10;
    doc.setFontSize(18);
    doc.text("Training Sessions", 14, finalY);

    const trainingSessions = summaryReportVal.training_sessions;
    doc.setFontSize(12);
    doc.text(`Total Count: ${trainingSessions.total_count}`, 14, finalY + 10);
    doc.text(`Abandon Count: ${trainingSessions.abandon_count}`, 14, finalY + 16);
    doc.text(
      `Average Score: ${Number(trainingSessions?.average_score || 0).toFixed(2)}`,
      14,
      finalY + 22
    );

    // Simulation Sessions
    const simFinalY = finalY + 32;
    doc.setFontSize(18);
    doc.text("Simulation Sessions", 14, simFinalY);

    const simulationSessions = summaryReportVal.simulation_sessions;
    doc.setFontSize(12);
    doc.text(`Total Count: ${simulationSessions.total_count}`, 14, simFinalY + 10);
    doc.text(`Abandon Count: ${simulationSessions.abandon_count}`, 14, simFinalY + 16);
    doc.text(
      `Average Score: ${Number(simulationSessions?.average_score || 0).toFixed(2)}`,
      14,
      simFinalY + 22
    );

    // Save PDF
    doc.save("summary-report.pdf");
  };

  const prepareChartData = (data) => {
    const sortedEntries = Object.entries(data).sort((a, b) => {
      const monthA = parseInt(a[0].split("-")[1], 10); // Extract the month number
      const monthB = parseInt(b[0].split("-")[1], 10); // Extract the month number
      return monthA - monthB; // Sort in ascending order of months
    });

    // Separate the sorted entries into labels and values
    const labels = sortedEntries.map((entry) => entry[0]);
    const values = sortedEntries.map((entry) => entry[1]);

    return {
      labels,
      datasets: [
        {
          label: "Monthly Average Score",
          data: values,
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  const chartData = prepareChartData(monthlyAvgScoreVal);

  useEffect(() => {
    dispatch(getUserTrainingStats(spiltID));
    dispatch(getUserSimulationStats(spiltID));
    dispatch(getUserTrainingStatus(spiltID));
    dispatch(getUserSimultionStatus(spiltID));
    dispatch(userResumeStats(spiltID));
    dispatch(getUserCompletedResumes(spiltID));
    dispatch(getUserAvgScore(spiltID));
    dispatch(getUserFeedbackSatisfaction(spiltID));

    dispatch(monthlyAvgScore(spiltID));
    dispatch(careerReadiness(spiltID));
    dispatch(summaryReport(spiltID));
    dispatch(loginFrequency(spiltID));
    dispatch(timeSpent(spiltID));
    dispatch(simulationScorePerUser(spiltID));
    dispatch(pRoundScorePerUser(spiltID));
    dispatch(simulationSessionTopScore(spiltID));
    dispatch(pRoundSessionTopScore(spiltID));
    dispatch(incompletionRates(spiltID));
  }, []);

  return (
    <MDBox pt={10} pb={3}>
      {loading && <Loader />}
      <Grid container spacing={6}>
        <Grid
          display="flex"
          justifyContent="end"
          item
          xs={12}
          sx={{ paddingTop: "0px !important" }}
        >
          <Button variant="contained" color="primary" sx={{ color: "#fff" }} onClick={generatePDF}>
            Download Summary Report
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="model_training"
                  title="Practice Rounds"
                  count={userTrainingStats?.total_sessions}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Interview Simulation Taken"
                  count={userSimulationStats?.total_sessions}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="article"
                  title="Resumes"
                  count={userResumes}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="article"
                  title="Resumes Avg Score"
                  stringText={typeof resumeAvgScore === "string" ? resumeAvgScore : ""}
                  count={typeof resumeAvgScore === "number" ? resumeAvgScore : ""}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  icon="person"
                  title="Resumes Completed"
                  count={compltedResumeCount}
                />
              </MDBox>
            </Grid>

            {/* NEW Cards */}
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="model_training"
                  title="P Rounds Completion"
                  displayIcon={trainingStatus ? "check" : "close"}
                  categories={roundsCompletionData?.categories}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="leaderboard"
                  title="Interview Simulation Completion"
                  displayIcon={simulationStatus ? "check" : "close"}
                  categories={simulationData?.categories}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="article"
                  title="Avg Interview Score"
                  count={averageScore}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="secondary"
                  icon="grading"
                  title="Feedback and Satisfaction"
                  count={satisfaction}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="update"
                  title="Career Readiness"
                  count={careerReadinessVal}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="login"
                  title="Login Frequency"
                  count={loginFrequencyVal}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="timelapse"
                  title="Time Spent On Platform"
                  count={formatTimeSpent(timeSpentVal)}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="scoreboard"
                  title="Average Simulation Score"
                  count={Number(simulationScorePerUserVal).toFixed(2)}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="scoreboard"
                  title="Average P Rounds Score"
                  count={Number(pRoundScorePerUserVal).toFixed(2)}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="scoreboard"
                  title="Top Simulation Score"
                  count={Number(simulationSessionTopScoreVal).toFixed(2)}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="scoreboard"
                  title="Top P Rounds Score"
                  count={Number(pRoundSessionTopScoreVal).toFixed(2)}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="pendingActions"
                  title="Incomplete Simulations"
                  count={`${incompletionRatesVal?.incomplete_simulations || 0}/${
                    incompletionRatesVal?.total_simulations || 0
                  }`}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="pendingActions"
                  title="Incomplete P Rounds"
                  count={`${incompletionRatesVal?.incomplete_trainings || 0}/${
                    incompletionRatesVal?.total_trainings || 0
                  }`}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox mb={1.5}>
                <Bar
                  data={chartData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                      title: {
                        display: true,
                        text: "Monthly Average Score",
                      },
                    },
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default UserStats;

import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import moment from "moment";
import React, { useEffect, useState } from "react";
import UserImg from "../../../assets/images/logos/user.png";
import TaskFeedbackSection from "components/TaskFeedbackSection";
import TaskChatSection from "components/TaskChatSection";
import { useDispatch, useSelector } from "react-redux";
import { getSingleTask } from "store/Slices/TaskSlice";
import { useNavigate, useParams } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { getJoinedUsers } from "store/Slices/usersSlice";
import { addTaskFeedback } from "store/Slices/TaskSlice";
import { toast } from "react-toastify";
import Loader from "components/Loader";
import AssignStatusModal from "components/AssignStatusModal";
import { getRouteByNumber } from "context";
import { permission } from "context";
import { featurePermissionsObject } from "context";

const TaskDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const spiltID = id?.split("_")?.[0];
  const { getSingleTaskData, loading } = useSelector((state) => state.task);
  const { joinedUsers } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.auth);
  const { orgTheme } = useSelector((state) => state.oraganizationTheme);
  const [feedback, setFeedback] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [custumerId, setCustumerId] = useState(null);
  const [storePermissions, setStorePermissions] = useState(null);
  const parsedObject = JSON.parse(user?.rolePermissions);
  let pagePermissions;
  let featurePermissions;
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const handleSubmitFeedback = (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("feedback", feedback);
    form.append("customer_id", custumerId);
    form.append("reviewed_status", "REVIEWED");
    dispatch(
      addTaskFeedback({
        payload: form,
        paramId: spiltID,
        onSuccess: () => {
          setFeedback("");
          setCustumerId(null);
          dispatch(
            getSingleTask({
              payload: spiltID,
              onSuccess: (res) => {
                toast.success("Feedback added successfully");
              },
            })
          );
        },
      })
    );
    setOpenModal(false);
  };

  const handleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    boxShadow: 24,
    p: 4,
    maxHeight: "80vh",
    overflowY: "auto",
  };

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };

  useEffect(() => {
    if (pagePermissions !== permission.admin && pagePermissions !== permission.tasks) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
    if (featurePermissions) {
      const data = featurePermissionsObject(featurePermissions);
      setStorePermissions(data);
    }
  }, []);

  useEffect(() => {
    dispatch(
      getSingleTask({
        payload: spiltID,
        onSuccess: (res) => {},
      })
    );
    dispatch(getJoinedUsers());
  }, []);
  return (
    <>
      <DashboardLayout>
        {loading && <Loader />}
        <Card sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    mb: 0,
                    fontWeight: "medium",
                    fontSize: "24px",
                    color: "#000",
                    textTransform: "capitalize",
                  }}
                >
                  {getSingleTaskData?.task_details?.title}
                </Typography>
                <Box display="flex" alignItems="center" gap={2}>
                  <Button
                    variant="contained"
                    sx={{
                      color: "#fff",
                      bgcolor: "#000",
                      "&:hover": {
                        bgcolor: "darkslategray",
                      },
                    }}
                    onClick={handleOpen}
                  >
                    Task Status
                  </Button>
                  <Chip
                    label={getSingleTaskData?.task_details?.priority}
                    sx={{
                      padding: "6px 8px",
                      borderRadius: "100px",
                      gap: "5px",
                      fontSize: "18px",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      alignItems: "center",
                      backgroundColor:
                        getSingleTaskData?.task_details?.priority === "HIGH"
                          ? "#DC3545"
                          : getSingleTaskData?.task_details?.priority === "LOW"
                          ? "#28a745"
                          : "#ffc107",
                      color: "#fff",
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  my: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    src={orgTheme?.file_url || UserImg}
                    alt="admin"
                    sx={{ width: 50, height: 50, borderRadius: "50%" }}
                  />
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "medium", color: "#000", textTransform: "capitalize" }}
                  >
                    {getSingleTaskData?.task_details?.organization_name}&nbsp;
                  </Typography>
                </Box>

                <Typography
                  variant="h6"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "400",
                    color: "#000",
                  }}
                >
                  - Due-Date:&nbsp;
                  {moment(getSingleTaskData?.task_details?.created_at).format("DD-MM-YYYY")}
                </Typography>
              </Box>

              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  mt: 1,
                  color: "#000",
                  textAlign: "justify",
                  lineHeight: 1.5,
                  // fontSize: "16px",
                }}
              >
                {getSingleTaskData?.task_details?.description}
              </Typography>

              {getSingleTaskData?.task_details?.task_file && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h5" sx={{ mb: 2, color: "#000", fontWeight: "bold" }}>
                    File:
                  </Typography>
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-block",
                      pl: 4,
                      mt: -2,
                    }}
                  >
                    <img
                      src="https://www.iconpacks.net/icons/2/free-file-icon-1453-thumb.png"
                      alt="file-icon"
                      height={100}
                      width={100}
                    />
                    <a
                      href={getSingleTaskData?.task_details?.task_file}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconButton
                        disabled={handleDisabled("create")}
                        sx={{
                          position: "absolute",
                          right: 0,
                          top: -15,
                          left: "77%",
                          cursor: "pointer",
                          display: "block",
                        }}
                      >
                        <CloudDownloadOutlinedIcon fontSize="large" />
                      </IconButton>
                    </a>
                  </Box>
                </Box>
              )}

              {getSingleTaskData?.completed_task_attachments?.length > 0 && (
                <>
                  <Typography variant="h5" sx={{ mb: 2, mt: 2, color: "#000", fontWeight: "bold" }}>
                    Attachments:
                  </Typography>
                  <Box sx={{ display: "flex", gap: 3, flexWrap: "wrap" }}>
                    {getSingleTaskData?.completed_task_attachments?.map((attachment) => (
                      <Box
                        sx={{
                          backgroundColor: "#f7f7f7",
                          py: 2,
                          borderRadius: "12px",
                          mt: 1,
                          minWidth: "350px",
                          width: "auto",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "30%",
                            }}
                          >
                            <Avatar
                              src={attachment?.image_url || UserImg}
                              alt="Profile Image"
                              sx={{ width: 60, height: 60 }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "70%",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                variant="h6"
                                component="span"
                                sx={{ color: "#000", fontWeight: 500 }}
                              >
                                {attachment?.customer_name}
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                sx={{ color: "#000", fontWeight: 400 }}
                              >
                                &nbsp;-&nbsp;
                                {moment(attachment?.created_at).format("hh:mm A")}
                              </Typography>
                            </Box>
                            <Typography
                              variant="body1"
                              sx={{
                                color: "#000",
                                mt: { xs: 0 },
                                fontWeight: 500,
                                lineHeight: 1.5,
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  display: "inline-block",
                                  pl: 0,
                                  mt: 0,
                                }}
                              >
                                <img
                                  src="https://www.iconpacks.net/icons/2/free-file-icon-1453-thumb.png"
                                  alt="file-icon"
                                  height={50}
                                  width={50}
                                />
                                <a
                                  href={attachment?.task_file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <IconButton
                                    disabled={handleDisabled("create")}
                                    sx={{
                                      position: "absolute",
                                      right: 0,
                                      top: -15,
                                      left: "70%",
                                      cursor: "pointer",
                                      display: "block",
                                    }}
                                  >
                                    <CloudDownloadOutlinedIcon fontSize="small" />
                                  </IconButton>
                                </a>
                              </Box>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                my: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  mb: 2,
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "22px",
                  textTransform: "capitalize",
                }}
              >
                {getSingleTaskData?.feedbacks.length} Feedbacks:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    color: "#fff",
                    bgcolor: "#000",
                    "&:hover": {
                      bgcolor: "darkslategray",
                    },
                  }}
                  onClick={handleOpenModal}
                  disabled={handleDisabled("create")}
                >
                  Add Feedbacks
                </Button>
                {/* <FilterDropdown /> */}
              </Box>
            </Box>

            <Box
              sx={{
                maxHeight: "70vh",
                overflow: "auto",
              }}
            >
              <TaskFeedbackSection />
            </Box>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                my: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  mb: 2,
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "22px",
                  textTransform: "capitalize",
                }}
              >
                {getSingleTaskData?.comments?.length} Comments
              </Typography>
              {/* <FilterDropdown /> */}
            </Box>

            <Box
              sx={{
                maxHeight: "70vh",
                overflow: "auto",
              }}
            >
              <TaskChatSection />
            </Box>
          </Box>
          <Divider />
        </Card>
        <Modal
          open={openModal}
          onClose={handleOpenModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Card sx={style}>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              p={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1} mb={1}>
                Add Feedback
              </MDTypography>
            </MDBox>
            <MDBox mx={1} p={1} mt={1}>
              <form onSubmit={handleSubmitFeedback}>
                <MDBox mb={2}>
                  <Box>
                    <MDBox px={1} pt={3} mb={1}>
                      <MDInput
                        type="text"
                        label="Feedback"
                        name="feedback"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        required
                        fullWidth
                      />
                    </MDBox>
                  </Box>
                  <Box>
                    <MDBox px={1} pt={1} mb={1}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Assign To:</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Assign To"
                          name="assignTo"
                          value={custumerId}
                          onChange={(e) => setCustumerId(e.target.value)}
                          required
                        >
                          {getSingleTaskData?.assigned_user_list?.length === 0 ? (
                            <MenuItem disabled value="">
                              <Typography>No users available</Typography>
                            </MenuItem>
                          ) : (
                            getSingleTaskData?.assigned_user_list?.map((option, index) => (
                              <MenuItem key={index} value={option.customer_id}>
                                {option.customer_name}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </MDBox>
                  </Box>
                </MDBox>
                <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
                  <MDButton variant="gradient" color="info" onClick={handleOpenModal}>
                    Cancel
                  </MDButton>
                  <MDButton variant="gradient" color="info" type="submit">
                    Submit
                  </MDButton>
                </MDBox>
              </form>
            </MDBox>
          </Card>
        </Modal>
        <AssignStatusModal
          open={open}
          onClose={handleOpen}
          // storeTaskId={storeTaskId}
          // storeActivePagination={storeActivePagination}
        />
      </DashboardLayout>
    </>
  );
};

export default TaskDetail;
